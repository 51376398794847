$(function () {

    $("#videoDestaque p iframe").addClass('embed-responsive-item');


    $(window).on('load', function () {
        $('#myModal').modal('toggle')
    });

    // compartilhamento

    //Facebook
    $('.facebook').click(function () {
        var share = 'https://www.facebook.com/sharer/sharer.php?u=';
        var urlOpen = $(this).attr('href');
        window.open(share + urlOpen, "_blank", "toolbar=yes, scrollbars=yes, resizable=yes, width=660, height=400");

        return false;
    });


});